import { UserPermission } from "../constants/user-permission";

const routeOptions = [
    { path: '/', name: 'default', view: 'auth/login', display: 'Home', meta: { showInMenu: false } },
    { path: '/401-forbidden', name: 'forbidden', view: 'errors/401-forbidden', display: '401 Forbidden' },
    { path: '/404-not-found', name: 'not-found', view: 'errors/404-not-found', display: '404 Page Not Found' },
    { path: '/login', name: 'login', view: 'auth/login' },
    { path: '/auth/forgot-password', view: 'auth/forgot-password' },
    { path: '/auth/reset-password', view: 'auth/reset-password', props: (route) => ({ code: route.query.code }) },
    { path: '/auth/confirm-email', view: 'auth/confirm-email', props: (route) => ({ userId: route.query.userId, token: route.query.token }) },
    {
        path: '/profile',
        view: 'profile/profile',
        display: 'Profile',
        meta: { auth: { permissions: UserPermission.GlobalLevelAccess }, showInMenu: false, icon: 'fa fa-user-circle', showChildren: true },
        children: [
            {
                path: '',
                display: 'My Profile',
                view: 'profile/edit-profile',
                meta: { auth: true },
            },
            {
                path: 'manage-logins',
                display: 'External Logins',
                view: 'profile/manage-logins',
                meta: { auth: true }
            },
            {
                path: 'change-password',
                display: 'Change Password',
                view: 'profile/change-password',
                meta: { auth: true }
            },
            {
                path: 'set-password',
                display: 'Set Password',
                view: 'profile/set-password',
                meta: { auth: true }
            }
        ]
    },
    {
        path: '',
        view: 'empty-layout',
        display: 'Settings',
        meta: { auth: true, showInMenu: true, isFooterMenu: true, icon: 'settings', showChildren: true },
        children: [
            {
                name: 'report-import',
                path: 'ReportImport',
                display: 'Import of Reports',
                view: 'admin/report-import/report-import',
                meta: { auth: { permissions: UserPermission.ReportImport } }
            }
        ]
    },
    { path: '*', redirect: { name: 'not-found' } }
];

function addDynamicImport(route) {
    if (!route.view)
        return route;

    if (route.children && route.children.length) {
        route.children = route.children.map(child => {
            return addDynamicImport(child);
        });
    }

    return {
        ...route,
        component: () => import(/* webpackChunkName: "[request]" */ `@/components/views/${route.view}`)
    }
}

const routes = routeOptions.map(route => {
    return addDynamicImport(route);
})

export { routes }