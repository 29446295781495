
    import { Component, Vue, Watch } from 'vue-property-decorator';

    import NavMenu from '@/components/views/nav-menu.vue';
    import SidebarMenu from '@/components/common/sidebar/SidebarMenu.vue';
    import { routes } from '@/routes/routes';
    import { SidebarMenuModel } from '@/types/common/ref-types';

    @Component({
        components: {
            'nav-menu': NavMenu,
            'sidebar-menu': SidebarMenu
        }
    })
    export default class BaseLayout extends Vue {
        $refs!: {
            sideMenu: SidebarMenuModel & Vue
        }

        menu: any[] = [];
        menuFooter: any[] = [];
        showBackDrop: boolean = false;
        menuCustom: any[] = [
            {
                after: 'manage',
                path: 'https://ileadr.com/rti-stored-success-guide/',
                external: true,
                display: '',
                icon: 'help',
                children: []
            }
        ];

        @Watch('isAuthenticated')
        isAuthenticatedWatcher() {
            this.drawSidebar();
        }

        @Watch('visibleRoutes')
        visibleRoutesWatcher() {
            this.drawSidebar();
        }

        get isAuthenticated() {
            return this.$auth.isAuthenticated;
        }

        mounted() {
            this.drawSidebar();
        }

        private closeMenu() {
            if (!this.showBackDrop)
                return;

            this.$refs.sideMenu.closeMenu();
        }

        private drawSidebar() {
            this.menu = [];
            this.menuFooter = [];
            this.visibleRoutes.forEach(route => {
                if (route.path === '/')
                    return;

                let children: any[] = [];

                if (route.meta.showChildren) {
                    route.children.forEach(child => {
                        let childrenChildrens: any[] = [];
                        let childData = {
                            id: '',
                            href: `${route.path}/${child.path}`,
                            isSubTitle: false,
                            title: child.display,
                            child: []
                        };

                        if (child.meta.showChildren)
                            child.children.forEach(otherChild => {
                                childrenChildrens.push({
                                    id: '',
                                    href: `${child.path}/${otherChild.path}`,
                                    title: otherChild.display,
                                });
                            });

                        childData.child = childrenChildrens;
                        childData.isSubTitle = childrenChildrens.length ? true : false;
                        children.push(childData);
                    });
                }

                let menuItem = {
                    id: '',
                    href: route.meta.showChildren ? '' : route.path,
                    title: route.display,
                    icon: route.meta.icon,
                    isSubTitle: false,
                    isFirstDisabled: true,
                    child: children
                };

                if (route.meta.isFooterMenu)
                    this.menuFooter.push(menuItem);
                else
                    this.menu.push(menuItem);

                this.menuCustom.forEach(customRoute => {
                    if (customRoute.after === route.name) {
                        let menuCustomItem = {
                            id: '',
                            href: customRoute.path,
                            external: true,
                            isSubTitle: false,
                            isFirstDisabled: true,
                            title: customRoute.display,
                            icon: customRoute.icon,
                            child: customRoute.children
                        };

                        if (route.meta.isFooterMenu)
                            this.menuFooter.push(menuCustomItem);
                        else
                            this.menu.push(menuCustomItem);
                    }
                });
            });

            if (!this.isAuthenticated) {
                this.menuFooter.push({
                    id: '',
                    href: '/login',
                    title: 'Login',
                    icon: 'login',
                    isSubTitle: false,
                    isFirstDisabled: true,
                    child: []
                });
                this.menuFooter.push({
                    id: '',
                    href: '/register',
                    title: 'Register',
                    icon: 'how_to_reg',
                    isSubTitle: false,
                    isFirstDisabled: true,
                    child: []
                });
            } else {
                this.menuFooter.push({
                    href: '',
                    id: 'account',
                    title: 'Account',
                    icon: 'account_circle',
                    isSubTitle: false,
                    isFirstDisabled: true,
                    child: []
                });
            }
        }

        private get visibleRoutes() {
            return routes.filter(route => {
                if (!(route.meta && route.meta.showInMenu))
                    return false;

                if (!route.meta.auth)
                    return true;
                return this.$auth.isRouteAccessible(route);
            });
        }
    }
