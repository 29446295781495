
    import { Component, Vue, Prop } from 'vue-property-decorator';

    @Component
    export default class SidebarMenuIcon extends Vue {
        @Prop({ type: String })
        protected icon!: string;
        @Prop({ type: String })
        protected id!: string;

        async logout() {
            await this.$auth.logout();
        }
    }
